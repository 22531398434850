import React from 'react';
import aurora from './aurora.jpeg';
import './App.css';

function App() {
  return (
    <div className="imageWrapper">
        <img src={aurora} className="image" alt="logo" />
        <p className="text">RELAX</p>
    </div>
  );
}

export default App;
